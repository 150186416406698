import { Link, graphql } from 'gatsby'
import React, { useState } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import Nav from '../components/Nav'

const BlogTop = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
      <Layout>
        <Helmet title="Developer's Blog" />
        <HeaderGeneric />

        <Waypoint
          onEnter={handleWaypointEnter}
          onLeave={handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={stickyNav} />

        <div id="main">
          <section id="top" className="main special">
            <header className="major">
                <h2>Developer's Blog</h2>
            </header>
          </section>
          <section id="index" className="main">
            <ol style={{ listStyle: `none` }}>
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug
                let eyeCatch
                if (post.frontmatter.eyeCatch) 
                {
                  eyeCatch = <Link to={post.fields.slug} itemProp="url"><GatsbyImage image={getImage(post.frontmatter.eyeCatch)} alt={title} className="image" /></Link>
                }

                return (
                  <li key={post.fields.slug} className="entry">
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <div className="entryText">
                        <header>
                          <h3>
                            <Link to={post.fields.slug} itemProp="url">
                              <span itemProp="headline">{title}</span>
                            </Link>
                          </h3>
                          <small>{post.frontmatter.date}</small>
                        </header>
                        <section>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </div>
                      <div className="entryImage">
                        {eyeCatch}
                      </div>
                    </article>
                  </li>
                )
              })}
            </ol>
            <div className="clear-both" />
          </section>
        </div>
      </Layout>
  )
}

export default BlogTop

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          eyeCatch {
            childImageSharp {
              gatsbyImageData(
                width: 120
              )
            }
          }
        }
      }
    }
  }
`;